import { gql } from 'graphql-request';

export const getProductCategoriesQuery = gql`
    query productCategories($limit: Int, $offset: Int) {
        productCategories: getProductCategories(limit: $limit, offset: $offset) {
            count
            rows {
                slug
                name
            }
        }
    }
`;

export const getProductCategoryQuery = gql`
    query productCategory($slug: String) {
        productCategory: getProductCategory(slug: $slug) {
            slug
            name
            products {
                slug
                name
                pausedAt
            }
        }
    }
`;
