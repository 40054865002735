import { createSignal, For, Show } from 'solid-js';
import { Title } from '@solidjs/meta';
import { Placements } from '@24hr/gullvikspraktikan-entities';
import { Container } from '../layout/container/container';
import type { StrategiesProps, Strategy as StrategyType, StrategyTreatment, ProductLite } from './strategies.d';
import { Strategy } from './strategy';
import { StyledTargets, StyledRoundedTag, StyledStrategiesInfoAndControls, StyledShowMoreStrategies } from './strategies.styles';
import { Header } from '../layout/header/header';
import { EffectColorMap } from './effect-color-map';
import { BreadCrumbs } from '../breadcrumbs/breadcrumbs';
import { AdsContainer } from '../../containers/ads/ads-container';
import { SkeletonLoader } from '../skeleton-loader/skeleton-loader';
import { AutumnSpringFilter } from './autumn-spring-filter';
import Button from '../button/button';

export const Strategies = (props: StrategiesProps) => {
    const strategies = () => props.strategiesData()?.strategies;
    const category = () => props.cropTypeCategoryData()?.cropTypeCategory;
    const cropType = () => props.cropTypeData()?.cropType;
    const crop = () => props.cropData()?.crop;
    const purposes = () => props.purposesData()?.purposes;
    const purpose = () => purposes()?.rows.find((purpose) => purpose.slug === props.purposeSlug);
    const [springAutumnFilter, setSpringAutumnFilter] = createSignal<StrategyType['springAutumnTreatment']>('');
    const [showAllStrategies, setShowAllStrategies] = createSignal(false);

    const selectedTreatments = () => {
        return purpose()
            ?.treatments?.filter((t) => props.selectedTreatments.includes(t.slug))
            .map(({ name, combined, slug }) => ({ name, combined, slug }));
    };

    const combinedTreatmentSlugs = () =>
        selectedTreatments()
            ?.filter(({ combined }) => !!combined)
            .map(({ slug }) => slug) || [];

    const selectedTreatmentNames = () => selectedTreatments()?.map(({ name }) => name);

    const links = () => [
        {
            text: category()?.name,
            url: `/strategier/${category()?.slug}/`,
        },
        {
            text: cropType()?.name,
            url: `/strategier/${category()?.slug}/${cropType()?.slug}`,
        },
        {
            text: crop()?.name,
            url: `/strategier/${category()?.slug}/${cropType()?.slug}/${crop()?.slug}`,
        },
        {
            text: purpose()?.name,
            url: `/strategier/${category()?.slug}/${cropType()?.slug}/${crop()?.slug}/${purpose()?.slug}`,
        },
    ];

    const metaTitle = () =>
        `Växtskyddsstrategier | ${purpose()?.name || ''} | ${crop()?.name || ''} | ${cropType()?.name || ''} | ${
            category()?.name || ''
        } - Gullvikspraktikan`;

    const combineTreatmentEffectValues = (treatments: StrategyTreatment[]) => {
        const filteredTreatments = treatments.filter(({ slug }) => props.selectedTreatments.includes(slug));
        return filteredTreatments.reduce((combinedValue: number, current: StrategyTreatment) => {
            combinedValue += current.effect;
            return combinedValue;
        }, 0);
    };

    const sortedStrategies = () => {
        if (!strategies()?.rows) {
            return [];
        }

        const strategiesProducts = () => (props.strategiesProductsData()()?.products?.products as ProductLite[]) || [];

        const sortedByCombinedEffects = [...(strategies()?.rows || [])]
            .filter((strategy) => {
                // If a product present on the strategy is not in the strategiesProducts array is
                // has either been paused or deleted so we should remove the strategy from the list.
                return strategy.products.every((product) => strategiesProducts().find((p) => p.uuid === product.uuid));
            })
            .sort((strategyA: StrategyType, strategyB: StrategyType) => {
                const strategyACombinedEffects = combineTreatmentEffectValues(strategyA.treatments);
                const strategyBCombinedEffects = combineTreatmentEffectValues(strategyB.treatments);

                if (strategyACombinedEffects !== strategyBCombinedEffects) {
                    return strategyBCombinedEffects - strategyACombinedEffects;
                }

                if (strategiesProducts().length) {
                    const strategyAProductUuids = strategyA.products.map(({ uuid }) => uuid);
                    const strategyBProductUuids = strategyB.products.map(({ uuid }) => uuid);

                    const strategyAProducts = strategiesProducts().filter((product: ProductLite) =>
                        strategyAProductUuids.includes(product.uuid)
                    );
                    const strategyBProducts = strategiesProducts().filter((product: ProductLite) =>
                        strategyBProductUuids.includes(product.uuid)
                    );

                    const strategyAProductRanks = strategyAProducts.map(({ rank }: ProductLite) => rank);
                    const strategyBProductRanks = strategyBProducts.map(({ rank }: ProductLite) => rank);

                    const strategyAProductRanksAverage =
                        strategyAProductRanks.reduce((a: number, b: number) => a + b, 0) / strategyAProductRanks.length;
                    const strategyBProductRanksAverage =
                        strategyBProductRanks.reduce((a: number, b: number) => a + b, 0) / strategyBProductRanks.length;

                    return strategyAProductRanksAverage - strategyBProductRanksAverage;
                }

                // fallback to sorting by effect (so no changes in ordering in this block as the combined effects are equal)
                // if no strategiesProducts are available for some reason
                return strategyBCombinedEffects - strategyACombinedEffects;
            });

        const filteredBySprintAutumnFilter: StrategyType[] = sortedByCombinedEffects.filter(({ springAutumnTreatment }) => {
            return springAutumnFilter() === '' ? true : springAutumnFilter() === springAutumnTreatment;
        });

        return showAllStrategies() ? filteredBySprintAutumnFilter : filteredBySprintAutumnFilter.slice(0, 3);
    };

    const displayShowAllStrategiesButton = () => {
        const strategyRows = strategies()?.rows || [];
        return strategyRows.length ? strategyRows.length > 3 : false;
    };

    const shouldShowFilters = () => {
        const options = strategies()?.rows?.map(({ springAutumnTreatment }) => springAutumnTreatment) || [];
        return [...new Set(options)].filter((s) => s).length > 1;
    };

    const shouldShowEffectColors = () =>
        strategies()?.rows?.some((s) => {
            return s.treatments.some(({ effect }) => effect > 40);
        });

    const numFilters = () => {
        if (shouldShowFilters() && shouldShowEffectColors()) {
            return 2;
        }

        return shouldShowFilters() || shouldShowEffectColors() ? 1 : 0;
    };

    const numStrategies = () => strategies()?.rows?.length || 0;

    return (
        <>
            <Title>{metaTitle()}</Title>
            <BreadCrumbs links={links()} prompt={selectedTreatmentNames()?.join(', ') || ''} />
            <Header header={crop()?.name || ''} />
            <Container header="Växtskyddsstrategier">
                <StyledTargets>
                    <For each={selectedTreatmentNames()}>{(name: string) => <StyledRoundedTag>{name}</StyledRoundedTag>}</For>
                </StyledTargets>

                <StyledStrategiesInfoAndControls numFilters={numFilters()}>
                    <Show when={shouldShowFilters()}>
                        <AutumnSpringFilter strategies={strategies} value={springAutumnFilter} onChange={setSpringAutumnFilter} />
                    </Show>
                    <Show when={shouldShowEffectColors()}>
                        <EffectColorMap />
                    </Show>
                </StyledStrategiesInfoAndControls>

                <Show when={numStrategies()} fallback={<SkeletonLoader height={110} numBlocks={2} />}>
                    <For each={sortedStrategies()}>
                        {(strategy: StrategyType) => (
                            <Strategy
                                strategy={strategy}
                                purposes={purposes()?.rows}
                                selectedTreatments={props.selectedTreatments}
                                combinedTreatmentSlugs={combinedTreatmentSlugs()}
                            />
                        )}
                    </For>
                </Show>

                <Show when={!showAllStrategies() && displayShowAllStrategiesButton() && numStrategies() > 3}>
                    <StyledShowMoreStrategies>
                        <Button
                            onClick={() => {
                                setShowAllStrategies(true);
                            }}
                            label="Visa alla"
                        />
                    </StyledShowMoreStrategies>
                </Show>

                <AdsContainer placement={Placements.Crop} slug={crop()?.slug} />
            </Container>
        </>
    );
};
