import { styled } from 'solid-styled-components';
import { MixingCode } from '../../containers/mixing-table/mixing-table-types';

const mixingColorsByStatus = (status: MixingCode) =>
    ({
        B: { background: '#b0c53e', text: 'black' },
        BA: { background: '#3c9236', text: 'white' },
        BR: { background: '#3c9236', text: 'white' },
        BO: { background: '#b8d1dd', text: 'black' },
        '-': { background: '#a82323', text: 'white' },
        'B*': { background: '#5a3780', text: 'white' },
        U: { background: '#5aa7dd', text: 'black' },
        IG: { background: '#efd632', text: 'black' },
    }[status]);

export const StyledText = styled.div<StyledTextProps>`
    font-size: 21px;
    font-weight: bold;
    color: black;
    ${(p) => (p.center ? 'text-align: center;' : '')};
`;

export type StyledTextProps = {
    center?: boolean;
};

export const StyledMixingRow = styled.div`
    margin: 1rem 0;
    background: #e6e6e6;
    border-radius: 6px;
`;

export const StyledMixingStatusInfo = styled.div<StyledMixingStatusInfoProps>`
    font-size: 18px;
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    border-radius: 6px;
    background-color: ${(p) => (p.status ? mixingColorsByStatus(p.status).background : '#efd632')};
    color: ${(p) => (p.status ? mixingColorsByStatus(p.status).text : 'black')};
    padding: 1rem;
`;
export type StyledMixingStatusInfoProps = {
    status: MixingCode;
};

export const StyledMixingNotes = styled.div`
    font-size: 16px;
    padding: 2rem;
`;

export const StyledMixingName = styled.div`
    width: 180px;
`;
